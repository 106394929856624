@value vBlue7 from "../../styles/variables.css";
@value tablet-up from "../../styles/breakpoints.css";

.page {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    background: linear-gradient(84deg, #62c5ff, #b84efa);
}

.navBar {
    background: transparent;
}

.content {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.content_form {
    display: flex;
    justify-content: center;
    margin-top: 230px;
}

.content_preview {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-basis: 100%;
    margin: 70px 60px 70px 0;
    background-color: vBlue7;
    border-radius: 60px;
}

.preview {
    max-width: calc(100% - 120px);
    max-height: calc(100% - 120px);
}

@media tablet-up {
    .content {
        /* padding: 0 0 40px; */
    }
}
