@value vRadius, vRed2 from "../../styles/variables.css";

.wrap {
    padding: 0;
}

.body {
    position: relative;
    padding: 0;
    border-radius: vRadius vRadius 0 0;
    overflow: hidden;
}

.body_bottomRadius {
    border-bottom-left-radius: vRadius;
    border-bottom-right-radius: vRadius;
}

.footer {
    padding: 24px 20px 20px;
    border-top: none;
    font-size: 1.125rem;
    line-height: 1.2;
    word-break: break-word;
    hyphens: auto;
}

.link {
    display: block;
}

.controls {
    display: flex;
    position: absolute;
    top: 15px;
    right: 20px;
}

.control + .control {
    margin-left: 16px;
}

.controlShare {
    width: 30px;
    height: 30px;
}

.controlLike {
    width: 34px;
    height: 30px;
}

.iconLikeOn svg path {
    fill: vRed2;
}

.iconLikeOff svg path {
    stroke: #000;
}
