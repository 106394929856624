@value vGutterX, vGray11 from "../../styles/variables.css";
@value tablet-up from "../../styles/breakpoints.css";

.auth {
    max-width: 100%;
    margin: 0 calc(vGutterX / 2) 20px;
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 4px;
}

.info {
    text-align: center;
}

.form {
    margin-top: 60px;
}

.body {
    padding: 20px 30px;
}

.bodyDesc {
    padding: 10px 0;
    font-size: .875rem;
    text-align: center;
    color: vGray11;
}

.footer {
    display: block;
    padding: 25px 30px;
    background-color: #f7f9fd;
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
    text-decoration: none;
}

.footerDesc {
    font-weight: 600;
    color: #000;
    text-align: center;
}

.footerLink {
    margin-top: 20px;

    color: #82aefc;
    text-align: center;
}

.logoWrap {
    height: 100px;
}

.logo {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: 100%;
}

.timer,
.confirm {
    padding: 20px 0 0;
}

.error {
    padding-top: 20px;
    padding-bottom: 20px;
}

@media tablet-up {
    .auth {
        width: 340px;
    }
}
