.avatarPreview {
    margin: 0 auto;
    width: 128px;
    height: auto;
    border-radius: 50%;
    overflow: hidden;
}

.group {
    padding: 0;
    composes: default from "../../components/Input/Input.module.css";
}

.input {
    background-color: inherit;
    font-size: inherit;
    color: inherit;
}

.radio {
    margin: 10px 10px 0 0;
}

.selectField {
    padding-left: 0;
    padding-right: 0;
    composes: input;
}

.citySelect {
    max-width: calc(100% - 66px); /* 66 - country select width */
}

.fieldCountryCode {
    padding-left: 14px;
    padding-right: 4px;
}

.fieldPhoto {
    display: flex;
    align-items: center;
    justify-content: center;
}

.labelPhoto {
    position: relative;
    cursor: pointer;
}

.controls {
    composes: controls from "../AuthPhoneForm/AuthPhoneForm.module.css";
}

.iconAddPhoto {
    position: absolute;
    top: 0;
    left: 0;
}
