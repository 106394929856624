@value vGray4 from "../../styles/variables.css";

.menu {
    margin: 0 0 0 auto;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: end;
}

.menuItem + .menuItem {
    margin-left: 24px;
}

.link {
    position: relative;
    display: block;
}

.selectField {
    text-transform: uppercase;
    composes: gray from "../Input/Input.module.css";
}

.selectOption {
    text-transform: uppercase;
}

.selectOption_active {
    pointer-events: none;
    color: vGray4;
}

.icon svg * {
    fill: #fff;
}
