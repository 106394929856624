@value vGutterX from "../../styles/variables.css";
@value tablet-up from "../../styles/breakpoints.css";

.registration {
    max-width: 100%;
    padding: 20px calc(vGutterX / 2);
    background-color: #fff;
}

.header {
    padding: 0 0 20px;
}

.logoWrap {
    display: flex;
    align-items: center;
    justify-content: center;
}

.body {

}

@media tablet-up {
    .registration {
        width: 360px;
        padding-left: 20px;
        padding-right: 20px;
    }
}
